<template>
  <fragment>
    <Search />
    <section :data-theme="`${$store.state.themeslug}soft`" class="padding-y-lg">
      <div class="container max-width-lg">
        <div class="grid grid-gap-sm items-center">
                    <div class="col-12 margin-bottom-sm">
            <router-link
              :to="{
                name: 'Home',
              
              }"
              class="no-underline font-bold"
            >
              <svg
                version="1.1"
                class="icon align-middle margin-right-xs"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 492 492"
                style="enable-background: new 0 0 492 492;"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
			C492,219.198,479.172,207.418,464.344,207.418z"
                    />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
              Terug</router-link
            >
          </div>
          <div class="col-3 col-9@lg">
  
               <span class="badge badge--primary-light margin-right-sm"
                ><i class="icon-thema margin-right-xs"></i> Onderwerp</span
              >
       <div class="text-component margin-y-sm inline-block">
  
              <h4 class="color-primary">{{ title }}</h4>
            </div>
            
          </div>

          <div class="col-12" v-if="subthemas && subthemas.length">
       
            <h6 class="color-primary margin-bottom-sm">   <i class="icon-filter text-sm margin-right-xxs"></i>Filter op onderwerp</h6>
            <span
              @click.prevent="filterContent('all')"
              class="badge filter__btn padding-y-md margin-right-sm margin-bottom-sm cursor-pointer"
              :class="currentFilter === 'all' && 'badge--primary'"
            >
              Alle onderwerpen
            </span>
            <span
              @click.prevent="filterContent(subthema)"
              class="badge filter__btn padding-y-md margin-right-sm margin-bottom-sm cursor-pointer"
              :class="currentFilter === subthema && 'badge--primary'"
              v-for="subthema in subthemas"
              :key="subthema"
            >
              {{ subthema }}
            </span>
          </div>
        </div>

        <div
       
          class="grid grid-gap-sm js-filter"
          data-filter-animation="off"
          id="items-gallery"
        >
          <div
            :key="item.id"
            v-for="item in content"
            class="col-6 col-3@md js-filter__item"
          >
            <ContentCard :uid="item.id" :data="item" />
          </div>
        </div>
      </div>
      <Modal />
    </section>
  </fragment>
</template>

<script>
// @ is an alias to /src
import { uniq } from "lodash";
import Search from "@/components/Search.vue";
import ContentCard from "@/components/ContentCard.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "ThemaOverview",
  components: {
    Search,
    ContentCard,
    Modal,
  },
  data() {
    return {
      content: [],
      currentFilter: 'all'
    };
  },
  mounted() {
   
      this.setContent();
    let frontEnd = document.createElement("script");
    frontEnd.setAttribute("src", "/scripts/filter.js");
    document.body.appendChild(frontEnd);
  },
  created() {
  
  },
  methods: {
    subthemastring(themastring) {
      return themastring.split("/")[1];
    },
    setContent() {
      this.content = this.$store.state.content.filter((item) => {
        return item.thema === this.slug;
      });

    },
    filterContent(subthema) {
     
      if (subthema === "all") {
        this.currentFilter = 'all';
        this.content = this.items;
      } else {
        this.currentFilter = subthema;
        this.content = this.items.filter((item) => {
          return item.subthema_title === subthema;
        });
      }
    },
  },
  watch: { 
  '$route.params.title': {
    handler: function() {
 
      this.setContent();
    },
    deep: true,
    immediate: true
  }
},
  computed: {
    title() {
      return this.$route.params.title;
    },
    slug() {
      return this.$route.params.slug;
    },
    items() {
      return this.$store.state.content.filter((item) => {
        return item.thema === this.slug;
      });
    },
    // subthemas() {
    //   if (!this.content.length) return;
    
    //   let subThemas = this.items.map((item) => {
    //     return item.subthema_title;
    //   });

    //   return uniq(subThemas).length > 1 ? uniq(subThemas) : null;
    // },
subthemas() {
  if (!this.content.length) return;

  let subThemas = this.items.map((item) => {
    return item.subthema_title;
  });

  let uniqueSubThemas = uniq(subThemas);

  if (uniqueSubThemas.length <= 1) return null;

  uniqueSubThemas.sort();

  const overigIndex = uniqueSubThemas.indexOf('Overig');
  const fietsenIndex =  uniqueSubThemas.indexOf('Fietsen');

  if (fietsenIndex !== -1) {
    uniqueSubThemas.splice(fietsenIndex, 1);
    uniqueSubThemas.push('Fietsen');
  }

  if (overigIndex !== -1) {
    uniqueSubThemas.splice(overigIndex, 1);
    uniqueSubThemas.push('Overig');
  }



  return uniqueSubThemas;
}

  },
};
</script>
